
import ValidationMixin from "@/mixins/validation";
import { ZoneTypes, SiteZone } from "@/store/modules/zone/zone.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const zoneX = namespace(ZoneTypes.MODULE);

@Component({})
export default class EditZone extends Mixins(ValidationMixin) {
  @zoneX.State(ZoneTypes.DELETE_ZONE_STATE)
  public deleteZone!: DefaultState;

  @zoneX.State(ZoneTypes.ZONE_DATA)
  public issueZone!: SiteZone;

  @zoneX.Mutation(ZoneTypes.SET_DELETE_ZONE_DIALOG)
  public setDeleteZone!: (deleteZone: boolean) => void;

  @zoneX.Action(ZoneTypes.DELETE_ZONE)
  public deleteSiteZone!: () => Promise<void>;

  public updateZoneStep = 1;

  public isValid = false;

  @Ref("form") public formRef!: Validator;

  closeDeleteZoneDialog(): void {
    this.setDeleteZone(false);
  }
}
